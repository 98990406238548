<template>
  <v-bottom-navigation
    v-model="value"
    class="d-md-none d-flex mobile-navigation-bottom"
    color="primary"
    fixed
  >
    <v-btn to="/">
      <span class="">{{ $t("Accueil") }}</span>
      <img
        v-svg-inline
        class="icon mr-0"
        src="@/assets/images/icons/home.svg"
        alt=""
      >
    </v-btn>

    <!--    <v-btn to="/mobile-categories">-->
    <v-btn to="/search">
      <span>{{ $t("Categories") }}</span>

      <img
        v-svg-inline
        class="icon mr-0"
        src="@/assets/images/icons/category.svg"
        alt=""
      >
    </v-btn>

    <v-btn to="/cart">
      <span>{{ $t("Pannier") }}</span>
      <img
        v-svg-inline
        class="icon mr-0"
        src="@/assets/images/icons/bag.svg"
        alt=""
      >
    </v-btn>

    <v-btn
      :to="$store.getters.isLoggedIn?'/profile':'/login'"
    >
      <span>{{ $t("Profil") }}</span>
      <v-badge
        v-if="login && unreadNotifications.length"
        bordered
        color="error"
        :content="unreadNotifications.length === 0 ? '0' : (unreadNotifications.length>9?'9+':unreadNotifications.length) "
        overlap
        @click="inboxClicked"
      >
        <img
          v-svg-inline
          @click="inboxClicked"
          class="icon mr-0"
          src="@/assets/images/icons/user-2.svg"
          alt=""
        >
      </v-badge>
      <img
        v-else
        v-svg-inline
        class="icon mr-0"
        src="@/assets/images/icons/user-2.svg"
        alt=""
      >
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  export default {
    data: () => ({ value: 1, not_clicked: true}),

    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        login: 'login',
        notifications: 'notifications',
        install_asked: 'install_asked',
        displayNotification: 'display_notification',
      }),
    //   color () {
    //     switch (this.value) {
    //       case 0: return 'blue-grey'
    //       case 1: return 'teal'
    //       case 2: return 'brown'
    //       case 3: return 'indigo'
    //       default: return 'blue-grey'
    //     }
    //   },
    },
    methods: {
      inboxClicked () {
        // eslint-disable-next-line no-undef
        axios.get('notifications/read')
        this.notifications.map((item) => {
          item.isOpened = true
          item.read_at = new Date()
        })
        this.not_clicked = false
      },
    },
  }
</script>
<style lang="scss">
    .mobile-navigation-bottom {
        bottom: 0;
        z-index: 100 !important;
    }
</style>
